
/* Header */

header{
    z-index: 2000;
    width: 100%;
}
nav{
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    background-color: rgba(10,11,16,0);
}
.logo{
    padding: .5em;
    margin-left: 10px;
    font-size: 30px;
    width: 100px;
    height: 100px;
}
.logo img {
    width: 80px;
    height: 80px;
}
nav ul {
    display: flex;
    list-style-type: none;
    margin: 0px;
    padding: 20px;
}
nav ul li {
    padding: 1.3rem;
    justify-self: end;
}

nav ul li.btn p{
    background: hsl(0, 100%, 33%);
    color: #fff;
    padding: 14px 27px;
    transition: all ease 0.5s;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}
nav ul li.btn p:hover{
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}