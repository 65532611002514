.container {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.container .container-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.container .container-title h1 {
    cursor:default;
}
.flex-row {
  display: flex;
  gap: 30px;
}
.underline {
  text-decoration: underline;
}
