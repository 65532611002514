.card-container {
  width: 90%;
  max-width: 1200px;
  height: 130px;
  text-align: initial;
  margin: 20px;
  border: 2px solid red;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #a8000060;
  position: relative;
}
 .card-info {
    width: 100%;
    height: 100%;
    display: flex;
}

img{
    width: 130px;
    height: 100%;
    object-fit: cover;
}


.parent {
    width: 100%;
    height: 100%;

    padding: 20px;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 2;
}
.div1 {
  grid-area: 1 / 1 / 2 / 2; 
  display: flex;
  justify-content: space-between;
}
.div2 {
    grid-area: 2 / 1 / 3 / 2; 
}
.div3 {
    grid-area: 1 / 2 / 3 / 3;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}


@media (max-width: 700px) {
    .card-container {
        width: 90%;
        height: 250px;
    }
    .card-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }
    .parent{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .div3 {
        flex-direction: row;
    }
}