.contact-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 1000px;
  background: #0a0b1095;
  opacity: 0.9;
  transition: all 0.8s;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
}
.contact-card:hover {
  box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 60px;
}
.contact-card:hover {
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

 .img {
  width: 90%;
}
.info {
  width: 90%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
}
form.info{
  align-items: center;
}
.info.img{

  flex-direction: row;
  justify-content: flex-start;
}
 .info h1 {
  color: #fff;
}

.icon {
  font-size: 30px;
  color: #fff;
}

.inputBox {
  position: relative;
  width: 100%;
}

.inputBox-Radio{
  width: 100%;
  padding: 10px;
  border: 1px solid #ffffff55;
  background: transparent;
  outline: none;
  border-radius: 5px;
}
.inputBox input, .inputBox textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ffffff55;
  background: transparent;
  outline: none;
  border-radius: 5px;
  font-size: 1em;
  color: #fff;
  transition: 0.4s;
}
.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: #ffffff55;
  transition: 0.4s;
}

.inputBox textarea:valid ~ span,
.inputBox textarea:focus ~ span,
.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  color: #fff;
  background: #a80000;
  transform: translate(10px) translateY(-7px);
  font-size: 0.65em;
  padding: 2px 10px;
  border-radius: 2px;
}
.inputBox textarea:valid,
.inputBox textarea:focus,
.inputBox input:valid,
.inputBox input:focus {
  transform: scale(1.1);
  border: 1px solid #a80000;
}

.inputBox-Radio {
  display: flex;
  color: #ffffff55;
}
.boxRadio {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
}
/* input[type="radio"]:checked,
input[type="radio"]:focus {
  cursor: pointer;
  background-color: #e00000;
}
input[type="radio"]:checked + span {
  color: #e00000;
} */
/* .radio{
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  position: relative;
  padding-left: 30px;
}
.radio + .radio {
  margin-left: 20px;
}
.radio input[type="radio"]{
  display: none;
}
.radio span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #e00000;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}
.radio span:after {
  content: '';
  height: 8px;
  width: 8px;
  background: #e00000;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: 300ms ease-in-out 0s;
}
.radio input[type='radio']:checked ~ span:after {
  transform: translate(-50%, -50%) scale(1);
} */

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.button {
  
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  overflow: hidden;
  transition: 0.2s;
  transition-delay: 0.6s;
  border-radius: 5px;
  cursor: pointer;
}



.button:hover {
  border: none;
  color: #fff;
  background: #a80000;
  box-shadow: 0 0 10px #a80000, 0 0 40px #a80000, 0 0 80px #a80000;
}

.button span {
  position: absolute;
  display: block;
}
.button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #a80000);
}
.button:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}
.button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #a80000);
}
.button:hover span:nth-child(3) {
  right: 100%;
  transition: 0.6s;
  transition-delay: 0.3s;
}
.button span:nth-child(2) {
  top: -100%;
  right: 0%;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #a80000);
}
.button:hover span:nth-child(2) {
  top: 100%;
  transition: 0.6s;
  transition-delay: 0.15s;
}
.button span:nth-child(4) {
  bottom: -100%;
  left: 0%;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #a80000);
}
.button:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.6s;
  transition-delay: 0.45s;
}

.validation {
    color: red;
}



