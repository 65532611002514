.modal {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: #00000099;
}
.modal-content {
  position: relative;
  width: 95%;
  max-width: 1000px;
  background: #0a0b10ec;
  opacity: 0.9;
  transition: all 0.8s;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 30px;
  display: flex;

  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.modal-content:hover {
  box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 60px;
}
.modal-content:hover {
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
.close-modal-btn {
  padding: 10px;
  background: #00000090;
  color: #fff;
}

.button-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
}
.button-close-modal svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 15px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 0px;
  width:8px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: -10px;
  animation-delay: -0.48s;
}
.lds-facebook div:nth-child(2) {
  left: 0px;
  animation-delay: -0.3s;
}
.lds-facebook div:nth-child(3) {
  left: 10px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(4) {
  left: 20px;
  animation-delay: -0,16s;
}
.lds-facebook div:nth-child(5) {
  left: 30px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 30px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
